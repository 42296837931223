import { useMemo, useState } from "react";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const TypologhyTable = ({ regions, judges }) => {
  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Región</TableCell>
            <TableCell align="center">Coef.</TableCell>
            {judges.map((judge) => (
              <TableCell key={judge} align="center">
                {judge}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {regions.map((region) => (
            <TableRow key={region.name}>
              <TableCell component="th" scope="row">
                {region.name}
              </TableCell>
              <TableCell align="center">{region.coef}</TableCell>
              {judges.map((judge) => (
                <TableCell key={`${region.name}-${judge}`} variant="head" align="center">
                  {region.scores[judge] || "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const RegionTable = ({ data }) => {
  const [value, setValue] = useState('1');

  const processedData = useMemo(() => {
    const regions = {};
    const judges = new Set();
    const regionsFunc = {};
    const judgesFunc = new Set();

    data.regions.forEach((item) => {
      const regionName = item.region.name;
      const judge = item.judge;

      if (item.region.is_func) {
        const judgeFunc = judge.replace("J", "JF");
        judgesFunc.add(judgeFunc);
        if (!regionsFunc[regionName]) {
          regionsFunc[regionName] = {
            name: regionName,
            order: item.region.order,
            coef: item.region.coef,
            scores: {},
          };
        }
        regionsFunc[regionName].scores[judgeFunc] = item.score;
      } else {
        judges.add(judge);

        if (!regions[regionName]) {
          regions[regionName] = {
            name: regionName,
            order: item.region.order,
            coef: item.region.coef,
            scores: {},
          };
        }

        regions[regionName].scores[judge] = item.score;
      }
    });

    const sortedRegions = Object.values(regions).sort(
      (a, b) => a.order - b.order,
    );
    const sortedRegionsFunc = Object.values(regionsFunc).sort(
      (a, b) => a.order - b.order,
    );
    const sortedJudges = Array.from(judges).sort();
    const sortedJudgesFunc = Array.from(judgesFunc).sort();

    return {
      regions: sortedRegions,
      regionsFunc: sortedRegionsFunc,
      judges: sortedJudges,
      judgesFunc: sortedJudgesFunc,
    };
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!processedData) {
    return null;
  }

  if (!processedData.judges.length && !processedData.judgesFunc.length) {
    return null;
  }

  if (processedData.judges.length && !processedData.judgesFunc.length) {
      return <TypologhyTable regions={processedData.regions} judges={processedData.judges} />
  }

  if (!processedData.judges.length && processedData.judgesFunc.length) {
      return <TypologhyTable regions={processedData.regionsFunc} judges={processedData.judgesFunc} />
  }

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} variant="fullWidth" indicatorColor="secondary" textColor="secondary">
        <Tab label="MORF" value="1" />
        <Tab label="FUNC" value="2" />
      </TabList>
    <TabPanel value="1">
      <TypologhyTable regions={processedData.regions} judges={processedData.judges} />
    </TabPanel>
    <TabPanel value="2">
      <TypologhyTable regions={processedData.regionsFunc} judges={processedData.judgesFunc} />
    </TabPanel>
    </TabContext>
  );
};

export default RegionTable;
