import { useEffect, useState } from 'react';

const useFetchData = (url, shouldFetch = true) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      console.log("ERROR", error);
    }
  }, [error]);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + url, {
          credentials: 'include',
          headers: {'Content-Type': 'application/json'},
        });
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        const json = await response.json();
        setData(json);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, shouldFetch]);

  return { data, loading, error };
};

export default useFetchData;
