import React from "react";
import { CircularProgress } from "@mui/material";

class Loader extends React.PureComponent {
  render() {
    const styles = {
      position: "absolute",
      top: "50%",
      left: "50%",
    };

    return (
      <CircularProgress
        className={`${styles} ${this.props.class}`}
        color={this.props.color || "primary"}
      />
    );
  }
}
export default Loader;
