import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import useFetchData from "../../hooks/useFetchData.js";
import RegionTable from "./RegionTable.jsx";

export default function ShowRegionModal({ participantType, participantId }) {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const { data, loading, error } = useFetchData(
    `/public/participant/${participantType}/${participantId}/regions/`,
    open,
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ textDecoration: "underline", whiteSpace: "nowrap" }}
        color="dark"
        onClick={handleOpen}
        loading={loading}
        loadingPosition="end"
      >
        Notas por regiones
      </Button>

      {data && !error && (
        <Dialog onClose={handleClose} fullScreen={fullscreen} open={open}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "black",
              color: "white",
              padding: "0 0 0 1rem",
            }}
          >
            {data.number} - {data.name}
            <IconButton
              sx={{ color: "white" }}
              aria-label="close"
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <RegionTable data={data} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
